body {
    .asset-tech-specs {
        margin: 10px 0 0 80px;

        .tech-specs-asset-hd {
            padding: 4px 10px;

            h4 {
                @include elementStyles($size: $font15, $fontFamily: $ibmplexMedium, $weight: 500);

                span {
                    font-family: $ibmplexLight;
                    font-weight: 300;
                }
            }

            .right-cta {
                float: right;
            }
        }

        .edit-basic-info {
            position: absolute;
            top: -10px;
            right: 0px;
            z-index: 10;
            height: 30px;
            text-align: right;  
            cursor: pointer;
            color: #fff;
            background: #fff;
            left: 0;
            width: 100%;
            padding-right: 4px;
            padding-top: 4px;
        }

        .tech-specs-card {
            position: relative;

            .tech-specs-nav {
                position: absolute;
                top: 0;
                left: 0;
                width: 180px;
                height: 100%;
                border-right: 1px solid #d7d7d7;
                // padding-top: 30px;
                background: #f1f1f1;

                .tech-nav-link {
                    border-bottom: 1px solid #d7d7d7;

                    // &:first-child{
                    //     border-top: 1px solid #d7d7d7;
                    // }
                    a {
                        @include elementStyles($size: $font13, $color: #000, $fontFamily: $ibmplexMedium, $weight: 500);
                        display: block;
                        padding: 6px 10px;

                        &.active {
                            color: #5b5bff;
                            background: #fff;
                            position: relative;
                            right: -1px;
                        }
                    }
                }
            }
            .tech-specs-content{
                margin: 10px 0px 0px 180px;
                padding-top: 10px;
                .aircraft-weight-table{
                    table{
                        thead{
                            tr{
                                background: #B5BEDB;
                                &:hover{
                                    background: #B5BEDB;
                                }
                                .header-cell-border{
                                    border-right: 1px solid #d7d7d7;
                                    padding: 5px;
                                    p{
                                        text-align: center;
                                        border-bottom: 1px solid #d7d7d7;
                                    }
                                    .inner-table-hd{
                                        th{
                                            padding: 0 4px;
                                            border: none !important;
                                        }
                                    }
                                }
                            }
                        }
                        tbody{
                            tr{
                                background: #fff !important;
                                .content-cell{
                                    padding: 5px;
                                    .inner-table{
                                        td{
                                            border: none;
                                            padding: 0 4px !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .chip-sub-asmbly{
                    padding: 2px 5px;
                    border-radius: 4px !important;
                    font-size: 13px;
                    background: #dddddd;
                    width: max-content;
                }
                .link-cls{
                    color: #007bff;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }
    .status-1 {
        background: linear-gradient(90deg, #beed87, #d2e9b6 95%);
        color: #5ec264 !important;
    }

    .status-2 {
        background: linear-gradient(269deg, #ffdfcb, #ffb98d 95%);
        color: #ee7c26 !important;
    }

    .status-3 {
        background: linear-gradient(90deg, #a7c4ff, #729cf1 95%);
        color: #4c79d5 !important;
    }

    .status-4 {
        background: linear-gradient(180deg, #d7d7d7, #f6f6f6 95%, #fff);
        color: #333 !important;
    }

    .status-5 {
        background: linear-gradient(269deg, #e1e1e1, #b3b3b3);
        color: #2c2c2c !important;
    }
    .status-16 {
        background-image: linear-gradient(90deg, #808080, #b0b0b0 95%);
        color: #ffffff;
    }

    .status-17 {
        background-image: linear-gradient(90deg, #f1e577ee, #f2e784e9 95%);
        color: #000000;
    }
    .status-14 {
        background-image:linear-gradient(269deg, #a7c4ff 0%, #3a66be 100%);
        color:#244382;
    }
    .status-15 {
        background-image:linear-gradient(269deg, #a08ad1 0%, #7450bb 100%);
        color:#250f30;
    }
    .records-movement-modal{
        .folder-tree{
            padding: 15px;
            .folder-list{
                // overflow: auto;
                // height: 380px;
            }
            .folder-list-dest{
                // overflow: auto;
                // height: 335px;
            }
            .no-folders-cls{
                text-align: center;
                padding: 10px;
            }
            .add-new-folder{
                color: #007bff;
                text-align: right;
                width: 100%;
                padding: 10px;
                border-left: 1px solid #c9c9c9;
                border-right: 1px solid #c9c9c9;
                border-bottom: 1px solid #c9c9c9;
                .add-new-folder-btn{     
                    cursor: pointer;
                }
                .create-folder-action-btn{
                    margin: 10px 0px 0px 10px;
                }
            }
            .parent-fldr-lnk{
                color: #3746a1;
            }
        }
        
        .selected-movement-data{
            margin-top: 20px;
            padding:10px 15px;
            h4{
                @include elementStyles($size: $font15, $color: #3f51b5, $fontFamily: $ibmplexMedium, $weight: 500);
                margin-bottom: 20px;
            }
            .source-selected, .destination-selected{
                border-top: 1px solid #b4b4b4;
                h6{
                    @include elementStyles($size: $font15, $color: #000, $fontFamily: $ibmplexMedium, $weight: 500);
                }
                .folder-naming{
                    padding: 3px 5px;
                    border-bottom: 1px solid #d7d7d7;
                    &:last-child{
                        border-bottom: none;
                    }
                    p{
                        display: flex;
                        padding: 3px 5px;
                        border-bottom: 1px solid #d7d7d7;
                        &:last-child{
                            border-bottom: none;
                        }
                        span{
                            @include elementStyles($size: $font13, $color: #000, $fontFamily: $ibmplexRegular, $weight: 400);
                        }
                        svg{
                            margin-left: auto;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

.dropzone {
    min-height: 300px;
    width: 100%;
    border: 2px dashed #aaaaaa;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    cursor: pointer;
}

.dropzone:hover {
    background-color: #e0e0e0;
}