@import '/settings';
.mui-table-format{
    &.hovered-table{
      tr{
        background: #E6EBFB;
        cursor: pointer;
      }
    }
    .actions-cell{
      text-align: right;
      svg{
        cursor: pointer;
      }
    }
    thead{
      background: #B5BEDB;
      th{
        padding: 5px 10px;
        background: #B5BEDB;
        @include elementStyles($size: $font13, $color:rgb(0,0,0), $fontFamily: $ibmplexMedium, $weight:500);
        span{
          @include elementStyles($size: $font13, $color:rgb(0,0,0), $fontFamily: $ibmplexMedium, $weight:500);
          &.MuiTableSortLabel-root{
            &:hover{
              color: #000;
              .MuiTableSortLabel-icon{
                color: #000 !important;
              }
            }
            .MuiTableSortLabel-icon{
              opacity: 0.5 !important;
            }
          }
          &.MuiTableSortLabel-active{
            color: #496ac5;
            .MuiTableSortLabel-icon{
              color: #496ac5 !important;
              opacity: 1 !important;
            }
          }
          &.col-align-center{
            justify-content: center;
            display: flex;
          }
        }
        .col-align-center{
          justify-content: center;
          display: flex;
        }
      }
    }
    tbody{
      tr{
        &:hover{
          background: #eff0f5 !important;
        }
        &:nth-child(odd){
          background: #fff;
        }
        &:nth-child(even){
          background: #E6EBFB;
        }
        &.Mui-selected{
          background: rgba(245, 0, 87, 0.08);
        }
        td{
          word-break: break-word;
          padding: 5px 10px;
          @include elementStyles($size: $font15, $color:$black, $fontFamily: $ibmplexRegular, $weight:400);
        }
      }
    }
}
.margin-bottom40{
  margin-bottom: 40px;
}
.filter-ui-drawer{
  input[type="text"], textarea, input[type="number"]{
    height: auto !important;
  }
  .filter-ui-drawer-header, .filter-ui-drawer-body, .filter-ui-drawer-footer{
      padding: 10px 15px;
  }
  .filter-ui-drawer-header{
      border-bottom: 1px solid #d7d7d7;
      h4{
          @include elementStyles($size: $font16, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
      }
  }
  .filter-ui-drawer-body{
      overflow: auto;
      ::placeholder {
        opacity: 0.1;
        font-size: 14px;
      }
  }
  .filter-ui-drawer-footer{
      border-top: 1px solid #d7d7d7;
  }
}
.delete-record-modal{
  text-align: center;
  padding: 30px;
  h4{
      @include elementStyles($size: $font18, $color: #000000, $fontFamily: $ibmplexRegular);
  }
  .actions-cta{
    margin-top: 40px;
      button{
          margin-left: 10px;
          &.first-child{
          margin-left: 0;
          }
      }
  }
}