@import '../setting';
body {
  .page-loader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.1);
    overflow: hidden;
    cursor: not-allowed;
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      img {
        width: 100px;
      }
    }
  }
  footer {
    background-color: #191c26;
    padding: 70px 0 10px;
    .container {
      > .row {
        margin-bottom: 30px;
      }
    }
    h3 {
      @include elementStyles( 16px, #ffffff, normal, normal, $ibmplexRegular, null, null );
      margin-bottom: 20px;
    }
    ul {
      li {
        margin-bottom: 15px;
        a {
          @include elementStyles( 13px, #bec0c7!important, normal, normal, $ibmplexRegular, null, null );
          &:hover, &:active, &:focus {
            color: #ffffff!important;
          }
        }
      }
      &.images-list {
        li {
          display: inline-block;
          margin-right: 15px;
          a {
            display: inline-block;
            border-radius: 50%;
            border: solid 1px rgba(78, 200, 244, 0.16);
            height: 30px;
            width: 30px;
            position: relative;
            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              -webkit-transform: translate(-50%, -50%);
              -moz-transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
              -o-transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
    .copy-right {
      @include elementStyles(
        13px,
        #bec0c7,
        normal,
        normal,
        $ibmplexRegular,
        null,
        null
      );
      img {
        width: 100px;
      }
      p {
        margin-top: 10px;
        a {
          @include elementStyles( 13px, #bec0c7, normal, normal, $ibmplexRegular, null, null );
        }
      }
    }
  }
  .page-not-found {
    padding: 100px 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    text-align: center;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    h1 {
      font-family: $ibmplexSemiBold;
      font-size: 70px;
      color: #000;
    }
    h4 {
      font-family: $ibmplexMedium;
      font-size: 30px;
      color: #000;
    }
    a {
      text-decoration: underline;
    }
  }

  // Showing Toast Message UI Element
  .MessageWrapper {
    transform: translateX(460px);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    position: fixed;
    top: 20px;
    right: 20px;
    display: table;
    margin: 0 auto;
    z-index: 9999;

    &.open {
      transform: translateX(0px);
    }

    &.close {
      transform: translateX(460px);
    }

    .Message {
      margin-bottom: 15px;

      .info {
        background-color: #0074D9;
        color: #ffffff;
      }

      .warning {
        background-color: #F39C12;
      }

      .error {
        background-color: #FF4136;
      }

      .success {
        background-color: #2ECC40;
      }

      .Message-icon {
        display: table-cell;
        vertical-align: middle;
        padding: 5px 10px;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.25);
        color: #ffffff;

        img {
          width: 14px;
        }
      }

      .Message-body {
        display: table-cell;
        vertical-align: middle;
        padding: 5px 10px 4px;

        img {
          width: 12px;
          position: absolute;
          right: 8px;
          top: 8px;
          cursor: pointer;
        }

        >p {
          font-size: 14px;
          margin: 0px;
          color: #ffffff;
        }
      }
    }

    .Message.is-hidden {
      opacity: 0;
      height: 0;
      font-size: 0;
      padding: 0;
      margin: 0 auto;
      display: block;
    }

    .Message-button {
      position: relative;
      margin: 15px 5px -10px;
      background-color: rgba(0, 0, 0, 0.25);
      -webkit-box-shadow: 0 3px rgba(0, 0, 0, 0.4);
      box-shadow: 0 3px rgba(0, 0, 0, 0.4);
      border: none;
      padding: 10px 15px;
      font-size: 16px;
      color: #ffffff;
      outline: none;
      cursor: pointer;

      &:hover {
        background: rgba(0, 0, 0, 0.3);
      }

      &:active {
        background: rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0 0px rgba(0, 0, 0, 0.4);
        box-shadow: 0 0px rgba(0, 0, 0, 0.4);
        top: 3px;
      }
    }

    .Message-close {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.3);
      color: #ffffff;
      border: none;
      outline: none;
      font-size: 20px;
      right: 5px;
      top: 5px;
      opacity: 0;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  *, h1, h2, h3, h4, h5, h6, p, ul, li {
    padding: 0;
    margin: 0;
    border: none;
    float: none;
    outline: 0;
    &:hover {
      text-decoration: none;
      outline: none;
    }
    &:focus {
      box-shadow: none;
      outline: none !important;
      text-decoration: none;
    }
    &:active {
      box-shadow: none;
      outline: none !important;
      text-decoration: none;
    }
    ::-moz-focus-inner {
      border: 0;
      outline: none;
    }
  }
  a{
    cursor: pointer;
    &:hover,&:focus {
      text-decoration: none;
    }
  }
  input[type="submit"] {
    cursor: pointer;
  }
  .flex-center {
    justify-content: center;
  }
  .flex-centered {
    display: flex;
    display: -webkit-flex;
    flex-flow: wrap;
    -webkit-flex-flow: wrap;
    align-items: center;

    &:before,
    &:after {
      width: 0;
    }
  }
  .flex-not-centered {
    display: flex;
    display: -webkit-flex;
    flex-flow: wrap;
    -webkit-flex-flow: wrap;
    &:before,&:after {
      width: 0;
    }
  }
  .error-msg {
    font-size: 12px !important;
    color: red !important;
    padding: 3px !important;
  }
  // p tag
  .para {
    font-size: 16px;
  }
  // p/span tag in para if heading h5
  .para-ui {
    font-size: 14px;
  }
  // p/span tag in para if heading h6
  .caption-ui {
    font-size: 12px;
  }
  // label
  .label {
    font-size: 13px;
    color: #a0a0a0;

    &.checkbox-block {
      cursor: pointer;
    }
  }
  .autocomplete-dropdown-container {
    >div {
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      border-left: 1px solid #ddd;
      padding: 8px;
      font-size: 12px;
    }
  }
  .add-new, .primary-btn {
    color: #fff !important;
    padding: 6px 24px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 12px;
    background: #3f51b5;
    border-color: #3f51b5;
    text-transform: uppercase;
    &:hover {
      background: #3f51b5;
      border-color: #3f51b5;
    }
    &:disabled {
      background-color: #90cbff;
      border: 1px solid #007bff;
    }
  }
  .siq_bR {
    bottom: 10px !important;
    left: 7px !important;

    .zsiq_float {
      .zsiq_cnt {
        left: 70px !important;

        &::after {
          left: -205px !important;
          box-shadow: none !important;
        }
      }
    }
  }
  .zls-sptwndw {
    left: 0 !important;
  }
}
