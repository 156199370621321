// font family
$ibmplexLight : 'Conv_IBMPlexSans-Light';
$ibmplexRegular : 'Conv_IBMPlexSans-Regular';
$ibmplexMedium : 'Conv_IBMPlexSans-Medium';
$ibmplexSemiBold : 'Conv_IBMPlexSans-SemiBold';
$ibmplexBold : 'Conv_IBMPlexSans-Bold';


// color
$black: #000000;
$font22: 22px;
$font20: 20px;
$font18: 18px;
$font16: 16px;
$font15: 15px;
$font14: 14px;
$font13: 13px;
$font11: 11px;
$font9: 9px;
// extends Mixins
@mixin elementStyles($size: null, $color: null,  $lineHeight: null, $weight: null, $fontFamily: null, $margin: null, $padding: null){
  @if $size != null {
    font-size: $size;
  }
  @if $lineHeight != null {
    line-height: $lineHeight;
  }
  @if $weight != null {
    font-weight: $weight;
  }
  @if $color != null {
    color: $color;
  }
  @if $fontFamily != null {
    font-family: $fontFamily;
  }
  @if $margin != null {
    margin: $margin;
  }
  @if $padding != null {
    padding: $padding;
  }
}

