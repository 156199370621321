@import '/settings';
.console-sec-nav{
    position: fixed;
    z-index: 0;
    padding: 70px 0 90px;
    background: rgba(113,99,186,.9);
    width: 200px;
    top: 45px;
    left: 80px;
    bottom: 0;
    overflow: auto;
    .nav-link{
        padding: 0 4px;
        a{
            display: block;
            padding: 8px;
            color: #fff;
            font-family: $ibmplexRegular;
            font-size: $font14;
            &.active{
                background: #fff;
                border-radius: 3px;
                color: #000;
            }
        }
    }
}
tr{
    width: 100%;
    &:hover{
      background: #eff0f5 !important;
    }
    &:nth-child(odd){
      background: #fff;
    }
    &:nth-child(even){
      background: #E6EBFB;
    }
    &.Mui-selected{
      background: rgba(245, 0, 87, 0.08);
    }
    td{
      word-break: break-word;
      padding: 5px 10px;
      @include elementStyles($size: $font15, $color:$black, $fontFamily: $ibmplexRegular, $weight:400);
    }
}
