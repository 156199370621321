@font-face {
	font-family: 'Conv_IBMPlexSans-Bold';
	src: url('https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/fonts/IBMPlexSans-Bold/IBMPlexSans-Bold.eot');
    src: local('☺'), url('https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/fonts/IBMPlexSans-Bold/IBMPlexSans-Bold.woff') format('woff'),
     url('https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/fonts/IBMPlexSans-Bold/IBMPlexSans-Bold.ttf') format('truetype'),
      url('https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/fonts/IBMPlexSans-Bold/IBMPlexSans-Bold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Conv_IBMPlexSans-SemiBold';
	src: url('https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/fonts/IBMPlexSans-SemiBold/IBMPlexSans-SemiBold.eot');
    src: local('☺'), url('https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/fonts/IBMPlexSans-SemiBold/IBMPlexSans-SemiBold.woff') format('woff'),
    url('https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/fonts/IBMPlexSans-SemiBold/IBMPlexSans-SemiBold.ttf') format('truetype'),
     url('https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/fonts/IBMPlexSans-SemiBold/IBMPlexSans-SemiBold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Conv_IBMPlexSans-Medium';
	src: url('https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/fonts/IBMPlexSans-Medium/IBMPlexSans-Medium.eot');
    src: local('☺'), url('https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/fonts/IBMPlexSans-Medium/IBMPlexSans-Medium.woff') format('woff'),
    url('https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/fonts/IBMPlexSans-Medium/IBMPlexSans-Medium.ttf') format('truetype'),
     url('https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/fonts/IBMPlexSans-Medium/IBMPlexSans-Medium.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Conv_IBMPlexSans-Regular';
	src: url('https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/fonts/IBMPlexSans-Regular/IBMPlexSans-Regular.eot');
    src: local('☺'), url('https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/fonts/IBMPlexSans-Regular/IBMPlexSans-Regular.woff') format('woff'),
     url('https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/fonts/IBMPlexSans-Regular/IBMPlexSans-Regular.ttf') format('truetype'),
     url('https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/fonts/IBMPlexSans-Regular/IBMPlexSans-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Conv_IBMPlexSans-Light';
	src: url('https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/fonts/IBMPlexSans-Light/IBMPlexSans-Light.eot');
    src: local('☺'), url('https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/fonts/IBMPlexSans-Light/IBMPlexSans-Light.woff') format('woff'),
    url('https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/fonts/IBMPlexSans-Light/IBMPlexSans-Light.ttf') format('truetype'),
    url('https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/fonts/IBMPlexSans-Light/IBMPlexSans-Light.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
