body {
  input::-ms-reveal,
    input::-ms-clear {
      display: none;
    }
  .login-auth-section {
    position: relative;
  
    background-image: url('../images/auth_banner.png');
    background-size: cover;
    .password-section {
      position: relative;
      border-radius: 20px;
      .sparta-logo {
        width: 50px
      }
      .caption-ui,.form-p {
        font-size: 14px;
        padding: 0 0 8px 0;
      }

      .form-title {
        @include elementStyles($size: 24px, $fontFamily: $ibmplexMedium, $color: #000, $margin: 10px 0 10px 0);
        line-height: normal;
        font-weight: bold;
      }
      .back-icon {
        @include elementStyles(14px, 1.67, normal, $ibmplexRegular, null, null);
        display: inline-block;
        margin-bottom: 10px;

      }
      .forgot-pwd {
        @include elementStyles($size: 14px, $fontFamily: $ibmplexRegular, $color: #0093ff);
        line-height: 1.67;
        font-weight: normal;
        position: relative;
        bottom: 16px;
        text-align: end;
      }

      .condition {
        @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: #010101, $margin: 0 0 5px 0);
        line-height: 20px;
        font-weight: normal;
        letter-spacing: 0.3px;
        text-align: center;
        padding-bottom:5px;
      }

      p {
        @include elementStyles( $fontFamily: $ibmplexRegular, $margin: 0 0 0 0);
        line-height: 20px;
        letter-spacing: 1px;
      }

      label {
        @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: "#f44336", $margin: 0 0 15px 0);
        word-spacing: 2px;
        letter-spacing: 1px;
      }

      .app-slider {
        border-radius: 20px;
      }
      .img-slider-wrapper {
        background: #c3eaff;
        border-radius: 0px 20px 20px 0px;
        padding: 50px 30px;

        .application-info-card {
          img {
            margin: 0 auto;
            margin-bottom: 40px;
            width: 280px;

            &.fin-s,
            &.commverge {
              width: 240px;
            }
          }

          h4 {
            @include elementStyles($size: 24px, $fontFamily: $ibmplexMedium, $color: #000000, $margin: 0 0 15px 0);
          }

          p {
            @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #000000, $margin: 0 0 15px 0);
            line-height: 26px;
            word-spacing: 2px;
            letter-spacing: 1px;
          }
        }

        .image--slider {
          .slick-slide {
            .list-unstyled {
              li {
                font-size: 13px;
                padding-left: 10px;

                &::before {
                  content: "";
                  position: absolute;
                  width: 4px;
                  height: 4px;
                  background-color: #000000;
                  border-radius: 50%;
                  left: 0;
                  top: 7px;
                }
              }
            }
          }       

          .slick-dots {
            bottom: -35px;

            li {
              button {
                &::before {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }

  }
  @media screen and (max-width: 425px) {
    .modal-style {
      width: 90%; 
      max-width: 315px; 
      padding: 15px; 
    }}

  .modal-style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 325px;
    background-color: #fff;
    box-shadow: 24;
    padding: 20px;
    border-radius: 10px;


    button {
      margin: 2px 0 2px 0;
    }

    .otp-login-auth {
      width: 45px;

      input[type="text"] {
        height: 4px;
      }
    }

    .span-otp {
      text-align: center;
      font-size: 12px;
      padding: 8px;
    }
  }

  .console-forms {

    input[type="text"],
    textarea,
    input[type="number"] {
      height: auto !important;
    }

    .MuiInputBase-multiline {
      padding: 5px !important;
    }

    .MuiFormControl-marginNormal {
      margin-bottom: 0;
    }

    .MuiOutlinedInput-input {
      padding: 14px !important;
      @include elementStyles($size: 15px, $color: $black, $fontFamily: $ibmplexRegular, $weight: 400)
    }

    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
      padding: 0;
    }
    ::placeholder{
      opacity: 0.2;
    }

    .MuiTextField-root {
      label {
        @include elementStyles($size: 14px, $fontFamily: $ibmplexRegular, $weight: 500);
      }
    }
  }



  // t&c,datapolicy,staticbanner 

  .terms-conditions-section,.data-policy-section {
    .static-banner {
      background-image: url('https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/phase-2/background.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      padding: 16px 0;
      

      .login-cta {
        float: right;
        color: #fff;
      }
      
      h2 {
        @include elementStyles($size: 26px, $color: #fff, $fontFamily: $ibmplexMedium, $weight: 500, $margin: 30px 0px 15px 0);
      }
    }

    h3 {
      @include elementStyles($size: $font14, $color: $black, $fontFamily: $ibmplexMedium, $weight: 500, $margin: 40px 0px 10px 0px);
    }

    p {
      @include elementStyles($size: $font14, $color: #7a7a7a, $fontFamily: $ibmplexRegular);
    }

    ul {
      @include elementStyles($size: $font14, $color: #7a7a7a, $fontFamily: $ibmplexRegular);
    }

    ul {
      padding-left: 20px;
    }
  }
}

@media only screen and (max-width: 576px) {
  .login-auth-section{
    padding: 30px 0;
    box-sizing: border-box;
    .img-slider-wrapper{
      display: none;
    }
    .user-auth-form {
      padding: 20px;
      box-sizing: border-box;
    }
  }
}
// Small devices (landscape phones, 576px and up)
@media screen and (max-width: 765px) and (min-width: 576px){
  .login-auth-section{
    padding: 15px 0;
    .img-slider-wrapper{
      display: none;
    }
    .user-auth-form {
      padding: 20px 20px 60px 20px;
    }
  }
}
// Medium devices (tablets, 768px and up)
@media screen and (max-width: 992px) and (min-width: 765px){
  .login-auth-section{
    position: relative;
    .img-slider-wrapper{
      display: none;
    }
    .user-auth-form {
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%,-50%);
      // width: 100%;
      padding: 20px 20px 60px 20px;
    }
  }
}
// Large devices (desktops, 992px and up)
@media screen and (max-width: 1200px) and (min-width: 992px){
  .login-auth-section{
    position: relative;
    .centre-blk{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .user-auth-form {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      padding: 0 80px;
    }
  }
}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .login-auth-section{
    position: relative;
    .centre-blk{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .user-auth-form {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      padding: 0 80px;
    }
  }
}
