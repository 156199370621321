@import '../setting';

@media (max-width: 991px){
  body{
    overflow: auto !important;
    padding-bottom: 50px;
  }
  .MessageWrapper{
    width: 80%;
  }
}




