
.console-management-modal{
    .MuiFormControlLabel-root.Mui-disabled {
        cursor: not-allowed;
    }
    textarea:disabled{
        background: #f9f9f9 !important;
        cursor: not-allowed;
    }
    input[type="text"], textarea, input[type="number"]{
        height: auto !important;
    }
    .MuiInputBase-multiline{
        padding: 5px !important;
    }
    .MuiFormControl-marginNormal{
        margin-bottom: 0;
    }
    .MuiOutlinedInput-input{
        padding: 14px !important;
        @include elementStyles($size: 15px, $color: $black, $fontFamily: $ibmplexRegular, $weight:400)        
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
        padding: 0;
    }
    .MuiTextField-root {
        label{
            @include elementStyles($size: 14px, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
        }
    }
    .MuiDialogTitle-root{
        padding: 10px 24px;
        h2{
            @include elementStyles($size: 16px, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
        }
    }
    .MuiDialogContent-root{
        padding: 10px 24px;
    }
    &.add-edit-work-order-modal{
        .work-order-inspection-date{
            margin-top: 10px;
            h4{
                @include elementStyles($size: 14px, $color: $black, $fontFamily: $ibmplexMedium, $weight:500, $margin: 0 0 4px 0);
            }
        }
    }
    .MuiDialogActions-root{
        padding: 10px 24px;
    }
    .stages-table{
        td{
            vertical-align: top;
        }
    }
    .drag-drop-cnt{
        min-height: 120px !important;
    }
    .template-path-list{
        .path-heading{
            @include elementStyles($size: $font15, $color: #3f51b5, $fontFamily: $ibmplexMedium, $weight: 500);
            margin-bottom: 10px;
        }
    }
    .dataroom-nav-folders{
        text-align: right;
        width: 100%;
        padding: 10px 20px;
        // padding-left: 20px;
        // padding-bottom: 10px;
        // border-top: 1px solid #c9c9c9;
        border-bottom: 1px solid #c9c9c9;
        .parent-fldr-lnk{
            color: #3746a1 !important;
        }
        .no-folders-cls{
            text-align: center;
            padding: 10px;
        }
        .add-new-folder-btn{     
                color: #007bff;
                cursor: pointer;
            }
            .create-folder-action-btn{
                margin: 10px 0px 0px 10px;
            }
    }
    .notes-yellow{
        padding: 4px 18px;
        border-radius:2px;
        font-size: 11px;
        background-color: #fff1b6;
    }
}
.delete-role-modal{
    h4{
        @include elementStyles($size: 15px, $color: $black, $fontFamily: $ibmplexMedium, $weight:500)        
    }
    p{
        @include elementStyles($size: 13px, $color: $black, $fontFamily: $ibmplexRegular, $weight:400)        
    }
}
.add-edit-role-modal{
    .role-matrix{
        margin-bottom: 15px;
        .feature-name{
            @include elementStyles($size: 15px, $color: $black, $fontFamily: $ibmplexMedium, $weight:500, $padding:8px)        
        }
    }
}
.add-edit-lessee{
    .contact-add-edit-lessee{
       ::placeholder {
            color: #ececec;
            opacity: 1;
        }
    }
    .font-implex{
        font-family: $ibmplexMedium;
    }
}
.add-assembly-modal{
    .assembly-link-status{
        h4{
            @include elementStyles($size: 14px, $color: $black, $fontFamily: $ibmplexMedium, $weight:500, $margin:0 0 4px 0);
        }
        .yellow-card{
            background: rgb(250, 244, 231);
            border: 1px dotted rgb(241, 225, 192);
            padding: 15px;
        }
    }
}