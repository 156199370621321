body{
  footer{
    margin-left: auto;
    margin-right: auto;
  }
  footer.solution-footer{
    .copyright-section{
      text-align: center;
      img{
        width: 100px;
      }
      p{
        @include elementStyles($size: 11px, $fontFamily: $ibmplexRegular, $color: #bec0c7, $margin: 10px 0 0 0);
      }
    }
  }
  .MuiDialog-root{
    .request-a-demo-modal{
      input{
        height: 25px;
      }
      .MuiFormLabel-root {
        @include elementStyles(16px, #000000, normal, normal, $ibmplexSemiBold, null, null);
        &.module-header{
          font-size: 16px;
          padding: 10px 0 5px;
        }
      }
      .MuiFormControlLabel-root{
       .MuiTypography-body1{
          font-size: 14px;
        }
      }
      .PrivateSwitchBase-root-5{
        padding: 3px 9px;
      }
    }
    .btn-color{
      color:#ffffff;
      background-color: #8fd14e;
      box-shadow: none;
      &.Mui-disabled{
        color: rgba(0, 0, 0, 0.26);
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
    .btn-color-outlined{
      color: #8fd14e;
      border: 1px solid rgba(143, 209, 78, 0.5);
    }
  }
  .header-section{
    padding: 15px;
    ul{
      &.header-cta{
        float: right;
        li{
          padding: 0px 10px;
          cursor: pointer;
          &.active{
            font-family: $ibmplexSemiBold;
            color: #3f51b5;
          }
          .login-btn{
            border: 1px solid #3f51b5;
            border-radius: 4px;
            padding: 3px 10px 5px;
          }
        }
      }
    }
  }
  .whats-sparta-section{
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    background-image: url('../../../assets/img/banner_img.jpg');
    background-size: cover;
    padding: 100px 15px 140px;
    .content-wrapper{
      width: 80%;
      h2{
        @include elementStyles($size: 38px, $fontFamily: $ibmplexSemiBold, $color: #ffffff, $margin: 0 0 30px 0);
        line-height: 46px;
      }
      h4{
        @include elementStyles($size: 30px, $fontFamily: $ibmplexSemiBold, $color: #8fd14e, $margin: 0 0 30px 0);
      }
      p{
        @include elementStyles(22px, #ffffff, normal, normal, $ibmplexLight, null, null);
        line-height: 30px;
        margin-bottom: 20px;
        width: 80%;
        text-align: justify;
      }
    }
    .image-slider{
      padding: 75px 25px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 50px;
      img{
        width: 140px;
        display: inline-block;
      }
      p{
        @include elementStyles(16px, #ffffff, normal, normal, $ibmplexRegular, null, null);
        line-height: 26px;
        text-align: right;
      }
      h4{
        @include elementStyles(24px, #ffffff, normal, normal, $ibmplexRegular, null, null);
        text-align: right;
        margin-bottom: 10px;
      }
    }
    .slick-dots{
      li{
        &.slick-active{
          button{
            &:before{
              color: #ffffff;
              opacity: 1;
            }
          }
        }
        button{
          &:before{
            font-size: 10px;
            color: #ffffff;
            opacity: 0.7;
          }
        }
      }
    }
  }
  .module-features-section, .more-features-section, .partners-section, .aviation-business-section, .digital-success-section, .asset-lifecycle-section, .contact-us-section{
    padding: 50px 100px 30px;
    position: relative;
    .btn-color{
      background-color: #8fd14e;
      box-shadow: none;
    }
    &.module-features-section{
      background-position: center;
      background-repeat: no-repeat;
      display: block;
      background-image: url('../../../assets/img/banner_2.jpg');
      background-size: cover;
      .slider-wrapper{
        padding-top: 70px;
      }
    }
    &.more-features-section{
      background-position: center;
      background-repeat: no-repeat;
      display: block;
      background-image: url('../../../assets/img/banner_1.jpg');
      background-size: cover;
      padding-bottom: 40px !important;
      .slider-wrapper{
        img{
          &.slider-img-partners{
            width: 250px;
          }
        }
      }
    }
    &.aviation-business-section{
      background-image: url('../../../assets/img/banner4_image.jpg') !important;
    }
    &.partners-section{
      padding: 60px 15px;
      background-position: center;
      background-repeat: no-repeat;
      display: block;
      background-image: url('../../../assets/img/banner_2.jpg');
      background-size: cover;
      .partner-wrapper{
        text-align: center;
        margin-bottom: 50px;
        ul{
          li{
            img{
              width: 200px;
            }
          }
        }
      }
      .aviation-business-card{
        padding: 60px 20px;
        text-align: center;
        background: rgba($color: #000000, $alpha: 0.1);
        border-radius: 4px;
        h4{
          @include elementStyles(20px, #000000, normal, normal, $ibmplexSemiBold, null, null);
          margin-bottom: 10px;
        }
      }
    }
    &.asset-lifecycle-section, &.contact-us-section{
      text-align: center;
      padding: 80px 15px 100px;
      .content-wrapper{
        background: rgba(0, 0, 0, .1);
        padding: 60px 0px 70px;
        border-radius: 4px;
        &.contact-us{
          padding: 30px 0px;
          h4{
            margin-bottom: 10px;
          }
          p{
            margin-bottom: 30px;
          }
        }
        input[type="text"]{
          height: 5px;
        }
        h2{
          @include elementStyles(36px, #000000, normal, normal, $ibmplexSemiBold, null, null);
          margin-bottom: 15px;
        }
        h4{
          @include elementStyles(20px, #000000, normal, normal, $ibmplexSemiBold, null, null);
          margin-bottom: 10px;
        }
        ul{
          li{
            .text{
              @include elementStyles(16px, #000000, normal, normal, $ibmplexRegular, null, null);
              position: relative;
              top: 25px;
            }
          }
          &.btn-cta{
            margin-top:15px;
          }
        }
      }
    }
    &.contact-us-section{
      padding: 50px 15px;
    }
    &.digital-success-section{
      background-position: center;
      background-repeat: no-repeat;
      display: block;
      background-image: url('../../../assets/img/banner_1.jpg');
      background-size: cover;
      min-height: 540px;
      .slider-wrapper{
        padding: 20px 0px 10px;
        ul{
          li{
            @include elementStyles(14px, #000000, normal, normal, $ibmplexMedium, null, null);
            border-bottom: 1px solid #e7e7e7;
            padding: 18px 6px;
            cursor: pointer;
            &:last-child{
              border-bottom: none;
            }
          }
          .active{
            color: #3f51b5;
            background: #e7e7e7;
            padding: 18px 6px;
          }
        }
        img{
          &.slider-img{
            display: inline-block;
            width: 250px;
          }
        }
      }
      .dsa-content-wrapper{
        width: 70%;
      }
    }
    .tab-blk{
      .tab-head{
        @include elementStyles(14px, #000000, normal, normal, $ibmplexMedium, null, null);
        text-align: center;
        cursor: pointer;
        border-right: 1px solid #c4c4c4;
        padding: 10px;
      }
      .last-tab{
        @include elementStyles(14px, #000000, normal, normal, $ibmplexMedium, null, null);
        text-align: center;
        cursor: pointer;
        border-right: none;
        padding: 10px;
      }
      .active{
        @include elementStyles(14px, #3f51b5, normal, normal, $ibmplexMedium, null, null);
        background: #e7e7e7;
        border-radius: 2px;
        text-align: center;
        cursor: pointer;
        border-radius: 4px;
        padding: 10px;
      }
    }
    .MuiTabs-root{
      margin-bottom: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    }
    .module-header{
      text-align: center;
      margin-bottom: 20px;
      h2{
        @include elementStyles(32px, #000000, normal, normal, $ibmplexSemiBold, null, null);
        margin-bottom: 10px;
      }
      p{
        @include elementStyles(18px, #000000, normal, normal, $ibmplexRegular, null, null);
        line-height: 28px;
      }
    }
    .slider-wrapper{
      padding: 60px 0 80px;
      border-radius: 4px;
      h4{
        @include elementStyles(30px, #000000, normal, normal, $ibmplexSemiBold, null, null);
        text-transform: uppercase;
        margin-bottom: 20px;
      }
      p{
        @include elementStyles(20px, #000000, normal, normal, $ibmplexLight, null, null);
        line-height: 30px;
        margin-bottom: 60px;
        text-align: justify;
      }
      ul{
        &.btn-cta{
          margin-top: 15px;
          li{
            margin-right: 20px;
            &:first-child{
              padding-left: 0px;
            }
          }
        }
      }
      img{
        &.slider-img{
          width: 330px;
        }
      }
    }
    .slick-dots{
      li{
        button{
          &:before{
            font-size: 10px;
          }
        }
      }
    }
    .down-arrow{
      text-align: center;
      position: relative;
      img{
        width: 30px;
        cursor: pointer;
      }
      &.asset-lifecycle{
        top: 40px;
      }
      &.partners{
        top: 50px;
      }
    }
  }
  .asset-detail-section{
    padding: 30px 15px;
    .asset-detail-card{
      text-align: center;
      align-items: center;
      span{
        &.asset-head{
          @include elementStyles(20px, #8fd14e, normal, normal, $ibmplexSemiBold, null, null);
        }
        &.asset-detail{
          @include elementStyles(16px, #000000, normal, normal, $ibmplexRegular, null, null);
          margin-left: 10px;
        }
      }
    }
  }
  .more-features-section{
    .slider-wrapper{
      h4{
        text-transform: none;
      }
      p{
        margin-bottom: 40px;
        &.sub-text{
          margin-bottom: 20px;
          @include elementStyles(14px, #000000, normal, normal, $ibmplexRegular, null, null);
        }
      }
    }
    .content-wrapper{
      padding-left: 55px;
    }
    ul{
      &.module-nav-link, &.fins-sub-head{
        text-align: center;
        li{
          @include elementStyles(14px, #000000, normal, normal, $ibmplexMedium, null, null);
          padding: 10px 50px;
          border-right: 1px solid #c4c4c4;
          cursor: pointer;
          &:last-child{
            border-right: none;
          }
          &:not(:last-child){
            margin-right: auto;
          }
          .sub-text{
            @include elementStyles(14px, #000000, normal, normal, $ibmplexRegular, null, null);
            margin-top: 8px;
          }
        }
        .active{
          display: inline-block;
          background: #e7e7e7;
          padding: 10px 50px;
        }
        &.fins-sub-head{
          text-align: left;
          margin-bottom: 20px;
          li{
            @include elementStyles(14px, #000000, normal, normal, $ibmplexRegular, null, null);
            padding: 5px 15px;
            &:first-child{
              padding-left: 0;
            }
          }
        }
      }
      &.sub-head{
        li{
          @include elementStyles(14px, #000000, normal, normal, $ibmplexRegular, null, null);
          padding: 5px 15px;
          margin-top: 8px;
        }
      }
    }
    img{
      width: 280px;
    }
  }
}
@media (max-width: 575.98px){
  body{
    .asset-lifecycle-section, .digital-success-section{
      //display: none!important;
      padding: 0;
      .mobile-align{
       
        text-align: -webkit-center
      }
    }
    .header-section{
      padding: 10px 15px;
      img{
        width: 45px;
      }
      ul{
        &.header-cta{
          width: 100%;
          text-align: right;
          li{
            padding: 0px;
            .MuiButton-outlined{
              padding: 3px 10px;
            }
          }
        }
        
      }
    }
    .whats-sparta-section{
      padding: 50px 15px 40px;
      .content-wrapper{
        h2{
          font-size: 28px;
          line-height: 38px;
          margin: 0 0 15px 0;
        }
        h4{
          font-size: 24px;
          margin: 0 0 15px 0;
        }
        p{
          font-size: 16px;
          line-height: 24px;
          width: auto;
          text-align: left;
        }
      }
    }
    .asset-detail-section{
      padding: 20px 15px;
      .asset-detail-card{
        span{
          &.asset-head{
            font-size: 18px;
          }
          &.asset-detail{
            font-size: 14px;
            margin-left: 6px;
          }
        }
      }
    }
    .module-features-section, .more-features-section{
      padding: 25px 7px;
      .btn-color{
        padding: 5px 15px 4px;
        font-size: 13px;
      }
      .mobile-align{    
        text-align: -webkit-center
      }
      .tab-blk{
        .tab-head{
          border: none;
        }}
      .tab-wrapper-mobile{
       // display: none;
      }
      .content-wrapper{
        padding-left: 0;
      }
      .slider-wrapper{
        padding: 0 !important;
        img{
          &.slider-img{
           // display: none;
          }
        }
        h4{
          font-size: 24px;
          line-height: 34px;
          margin-bottom: 10px;
        }
        p{
          font-size: 16px;
          //text-align: left;
          margin-bottom: 25px;
        }
      }
    }
    .more-features-section{
      ul{
        &.module-nav-link{
         // display: none;
         li{
          border:none
         }
        }
      }
      img{
       // display: none;
      }
    }
    .partners-section{
      padding: 25px 15px!important;
      .module-header{
        h2{
          font-size: 28px;
        }
      }
      .partner-wrapper{
        margin-bottom: 15px!important;
        ul{
          li{
            img{
              width: 130px!important;
            }
          }
        }
      }
      .aviation-business-card{
        padding: 22px 15px 28px!important;
        h4{
          font-size: 18px!important;
        }
        p{
          font-size: 15px;
        }
      }
      .btn-color{
        padding: 5px 15px 4px;
        font-size: 13px;
        margin-top: 25px!important;
      }
    }
    .contact-us-section{
      padding: 30px 15px!important;
      .contact-us{
        padding: 30px 10px!important;
      }
    }
    .down-arrow{
     // display: none;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  body{
    padding-left: 0!important;
    br{
      display: none;
    }
    .whats-sparta-section{
      .image-slider{
        padding: 50px 25px;
        img{
          width: 100px;
          h4{
            font-size: 20px;
          }
          p{
            font-size: 14px;
          }
        }
      }
    }
  }
}
